var render = function render(){var _vm=this,_c=_vm._self._c;return _c('section',{staticClass:"form-wrapper"},[(_vm.showForm)?_c('div',[_vm._m(0),_c('van-form',{ref:"form"},[_c('van-field',{attrs:{"readonly":"","disabled":"","name":"applyName","label":"用户名","placeholder":"请输入姓名","required":""},model:{value:(_vm.form.applyName),callback:function ($$v) {_vm.$set(_vm.form, "applyName", $$v)},expression:"form.applyName"}}),_c('van-field-select',{attrs:{"options":_vm.idCardTypes,"fieldProps":{
          label: '证件类型',
          required: true,
          readonly: true,
          disabled: true,
          placeholder: '请选择证件类型',
        }},model:{value:(_vm.form.idCardType),callback:function ($$v) {_vm.$set(_vm.form, "idCardType", $$v)},expression:"form.idCardType"}}),_c('van-field',{attrs:{"readonly":"","disabled":"","name":"idCardNum","label":"证件号码","placeholder":"请输入证件号码","required":""},model:{value:(_vm.form.idCardNum),callback:function ($$v) {_vm.$set(_vm.form, "idCardNum", $$v)},expression:"form.idCardNum"}}),_c('van-field',{attrs:{"name":"phone","label":"手机号","placeholder":"请输入手机号","required":"","maxlength":"11"},model:{value:(_vm.form.phone),callback:function ($$v) {_vm.$set(_vm.form, "phone", $$v)},expression:"form.phone"}}),_c('van-field-area',{attrs:{"name":"domicile","fieldProps":{
          required: true,
          label: '户籍地址',
          placeholder: '请选择户籍地址',
        },"defaultField":""},model:{value:(_vm.form.domicile),callback:function ($$v) {_vm.$set(_vm.form, "domicile", $$v)},expression:"form.domicile"}}),_c('van-field',{attrs:{"name":"phone","label":"产业人才类别","placeholder":"请输入产业人才类别","required":"","disabled":""},model:{value:(_vm.form.industryTalentType),callback:function ($$v) {_vm.$set(_vm.form, "industryTalentType", $$v)},expression:"form.industryTalentType"}}),_c('van-field-select',{attrs:{"options":_vm.nations,"fieldProps":{
          label: '民族',
          required: true,
          placeholder: '请选择民族',
        }},model:{value:(_vm.form.nation),callback:function ($$v) {_vm.$set(_vm.form, "nation", $$v)},expression:"form.nation"}}),_c('van-field-select',{attrs:{"options":_vm.politics,"fieldProps":{
          label: '政治面貌',
          required: true,
          placeholder: '请选择政治面貌',
        }},model:{value:(_vm.form.politicCountenance),callback:function ($$v) {_vm.$set(_vm.form, "politicCountenance", $$v)},expression:"form.politicCountenance"}}),_c('van-field',{attrs:{"name":"workCmpy","label":"工作单位","placeholder":"请输入工作单位","required":"","maxlength":"100"},model:{value:(_vm.form.workCmpy),callback:function ($$v) {_vm.$set(_vm.form, "workCmpy", $$v)},expression:"form.workCmpy"}}),_c('van-field-select',{attrs:{"options":_vm.leaseTerms,"fieldProps":{
          label: '租赁期限',
          required: true,
          placeholder: '请选择租赁期限',
        }},model:{value:(_vm.form.leaseTerm),callback:function ($$v) {_vm.$set(_vm.form, "leaseTerm", $$v)},expression:"form.leaseTerm"}}),_c('div',[_c('van-divider',{style:({
            color: '#1989fa',
            borderColor: '#1989fa',
            padding: '0rem 0.3rem',
          }),attrs:{"content-position":"left"}},[_vm._v("家庭关系")]),_vm._l((_vm.form.family),function(item,i){return _c('div',{key:i},[_c('van-field-select',{attrs:{"options":_vm.familyTies,"fieldProps":{
              label: '关系',
              required: true,
              placeholder: '请选择关系',
            }},model:{value:(item.familyTies),callback:function ($$v) {_vm.$set(item, "familyTies", $$v)},expression:"item.familyTies"}}),_c('van-field',{attrs:{"name":"familyName","label":"姓名","placeholder":"请输入姓名","maxlength":"20","required":""},model:{value:(item.familyName),callback:function ($$v) {_vm.$set(item, "familyName", $$v)},expression:"item.familyName"}}),_c('van-field',{attrs:{"required":"","name":"famIdCardType","label":"证件类型","placeholder":"请输入证件类型","maxlength":"100","disabled":""},model:{value:(item.famIdCardType),callback:function ($$v) {_vm.$set(item, "famIdCardType", $$v)},expression:"item.famIdCardType"}}),_c('van-field',{attrs:{"name":"famIdCardNum","label":"身份证号","required":"","placeholder":"请输入身份证号","maxlength":"18"},model:{value:(item.famIdCardNum),callback:function ($$v) {_vm.$set(item, "famIdCardNum", $$v)},expression:"item.famIdCardNum"}}),_c('van-field',{attrs:{"label":"联系方式","placeholder":"请输入联系方式","maxlength":"100"},model:{value:(item.phone),callback:function ($$v) {_vm.$set(item, "phone", $$v)},expression:"item.phone"}}),_c('p',{staticClass:"fam-btns"},[_c('van-button',{staticClass:"btn",attrs:{"type":"default","size":"mini"},on:{"click":_vm.onAdd}},[_vm._v(" 新增 ")]),_c('van-button',{staticClass:"btn",attrs:{"type":"danger","size":"mini"},on:{"click":function($event){return _vm.onDelete(i)}}},[_vm._v(" 删除 ")])],1)],1)})],2)],1)],1):_c('div',[_c('van-empty',{attrs:{"description":"暂无产业人才认证,无法办理"}})],1)])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"notices"},[_c('p',{staticClass:"notice-item"},[_vm._v("*填写说明")]),_c('p',{staticClass:"notice-item"},[_vm._v("1、家庭成员须依据实际情况填写配偶及未成年子女")]),_c('p',{staticClass:"notice-item"},[_vm._v(" 2、申请人需在港区工作或创业并完整填写在工商局注册的单位全称 ")])])
}]

export { render, staticRenderFns }