<template>
  <!--
  *@des: 产业人才免租住房
  -->
  <section class="form-wrapper">
    <div v-if="showForm">
      <div class="notices">
        <p class="notice-item">*填写说明</p>
        <p class="notice-item">1、家庭成员须依据实际情况填写配偶及未成年子女</p>
        <p class="notice-item">
          2、申请人需在港区工作或创业并完整填写在工商局注册的单位全称
        </p>
      </div>
      <van-form ref="form">
        <van-field
          readonly
          disabled
          v-model="form.applyName"
          name="applyName"
          label="用户名"
          placeholder="请输入姓名"
          required
        />
        <van-field-select
          v-model="form.idCardType"
          :options="idCardTypes"
          :fieldProps="{
            label: '证件类型',
            required: true,
            readonly: true,
            disabled: true,
            placeholder: '请选择证件类型',
          }"
        ></van-field-select>
        <van-field
          readonly
          disabled
          v-model="form.idCardNum"
          name="idCardNum"
          label="证件号码"
          placeholder="请输入证件号码"
          required
        />
        <van-field
          v-model="form.phone"
          name="phone"
          label="手机号"
          placeholder="请输入手机号"
          required
          maxlength="11"
        />
        <van-field-area
          v-model="form.domicile"
          name="domicile"
          :fieldProps="{
            required: true,
            label: '户籍地址',
            placeholder: '请选择户籍地址',
          }"
          defaultField=""
        />
        <van-field
          v-model="form.industryTalentType"
          name="phone"
          label="产业人才类别"
          placeholder="请输入产业人才类别"
          required
          disabled
        />
        <van-field-select
          v-model="form.nation"
          :options="nations"
          :fieldProps="{
            label: '民族',
            required: true,
            placeholder: '请选择民族',
          }"
        ></van-field-select>
        <van-field-select
          v-model="form.politicCountenance"
          :options="politics"
          :fieldProps="{
            label: '政治面貌',
            required: true,
            placeholder: '请选择政治面貌',
          }"
        ></van-field-select>

        <van-field
          v-model="form.workCmpy"
          name="workCmpy"
          label="工作单位"
          placeholder="请输入工作单位"
          required
          maxlength="100"
        />
        <van-field-select
          v-model="form.leaseTerm"
          :options="leaseTerms"
          :fieldProps="{
            label: '租赁期限',
            required: true,
            placeholder: '请选择租赁期限',
          }"
        ></van-field-select>
        <!-- 家庭关系 -->
        <div>
          <van-divider
            content-position="left"
            :style="{
              color: '#1989fa',
              borderColor: '#1989fa',
              padding: '0rem 0.3rem',
            }"
            >家庭关系</van-divider
          >
          <div v-for="(item, i) in form.family" :key="i">
            <van-field-select
              v-model="item.familyTies"
              :options="familyTies"
              :fieldProps="{
                label: '关系',
                required: true,
                placeholder: '请选择关系',
              }"
            ></van-field-select>
            <van-field
              v-model="item.familyName"
              name="familyName"
              label="姓名"
              placeholder="请输入姓名"
              maxlength="20"
              required
            />
            <van-field
              v-model="item.famIdCardType"
              required
              name="famIdCardType"
              label="证件类型"
              placeholder="请输入证件类型"
              maxlength="100"
              disabled
            />
            <van-field
              v-model="item.famIdCardNum"
              name="famIdCardNum"
              label="身份证号"
              required
              placeholder="请输入身份证号"
              maxlength="18"
            />
            <van-field
              v-model="item.phone"
              label="联系方式"
              placeholder="请输入联系方式"
              maxlength="100"
            />
            <p class="fam-btns">
              <van-button class="btn" type="default" size="mini" @click="onAdd">
                新增
              </van-button>
              <van-button
                class="btn"
                type="danger"
                size="mini"
                @click="onDelete(i)"
              >
                删除
              </van-button>
            </p>
          </div>
        </div>
      </van-form>
    </div>
    <div v-else>
      <van-empty description="暂无产业人才认证,无法办理" />
    </div>
  </section>
</template>

<script>
import formMixin from "../mixin";
import Schema from "async-validator";
import { nations } from "../config";
import formRules from "./rules";
export default {
  name: "IndustryFreeRent",
  mixins: [formMixin],
  data() {
    return {
      showForm: false,
      politics: [
        { value: "中共党员(含预备党员)", label: "中共党员(含预备党员)" },
        { value: "民主党派", label: "民主党派" },
        { value: "无党派人士", label: "无党派人士" },
        { value: "团员", label: "团员" },
        { value: "群众", label: "群众" },
      ],
      leaseTerms: [
        { value: "1年", label: "1年" },
        { value: "2年", label: "2年" },
        { value: "3年", label: "3年" },
      ],
      nations,
      form: {
        applyName: "", //姓名
        idCardType: "居民身份证", //证件类型
        phone: "",
        idCardNum: "", //证件号码
        domicile: [], //户籍地址
        workCmpy: "", //工作单位
        industryTalentType: "", //人才类别
        politicCountenance: "", //政治面貌
        leaseTerm: "", //租赁年限
        nation: "",
        family: [
          {
            familyTies: "",
            familyName: "",
            famIdCardType: "居民身份证",
            famIdCardNum: "",
            phone: "",
          },
        ],
      },
      formRules: formRules,
      idCardTypes: [
        {
          value: "居民身份证",
          label: "居民身份证",
        },
      ],
      //家庭关系
      familyTies: [
        {
          value: "配偶",
          label: "配偶",
        },
        {
          value: "儿子",
          label: "儿子",
        },
        {
          label: "女儿",
          value: "女儿",
        },
      ],
    };
  },
  computed: {
    userInfo() {
      return this.$store.state.global.userInfo;
    },
  },
  watch: {
    userInfo() {
      if (this.userInfo) {
        this.authCheck();
      }
    },
  },
  created() {
    if (this.userInfo) {
      this.authCheck();
    }
  },
  methods: {
    authCheck() {
      const talentType = "0"; //产业人才
      this.$store
        .dispatch("policy/getUserTalentTypesByIdcard", {
          idCard: this.userInfo.idCard,
          userName: this.userInfo.rnName,
          type: talentType,
        })
        .then((res) => {
          if (res) {
            let strs = [];
            strs = res.map((item) => {
              return item.typeName;
            });
            let libraryStr = strs.join("/");

            this.form.industryTalentType = libraryStr;
            this.showForm = true;
          } else {
            this.authCheckFail();
          }
        })
        .catch((err) => {
          this.authCheckFail();
        });
    },
    authCheckFail() {
      this.$toast("暂无产业人才认证,无法办理");
      this.$emit("authCheck", false);
      this.showForm = false;
    },
    /*
     *@des: 新增家庭成员
     */
    onAdd() {
      this.form.family.push({
        familyTies: "",
        familyName: "",
        famIdCardType: "居民身份证",
        famIdCardNum: "",
        famDomicile: [],
      });
    },
    /*
     *@des: 删除家庭成员
     */
    onDelete(index) {
      if (this.form.family.length === 1) {
        this.$toast("最后一条不能删除");
        return;
      }
      this.form.family.splice(index, 1);
    },
    validate() {
      const validator = new Schema(this.formRules);
      return new Promise((resolve, reject) => {
        validator
          .validate(this.form, (errors) => {
            if (errors && errors.length) {
              this.$toast(errors[0].message);
            }
          })
          .then(async () => {
            resolve({
              ...this.form,
            });
          })
          .catch((err) => {
            console.log(err);
          });
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.form-wrapper {
  margin-top: 0.2rem;
  background: #fff;
  .title-gutter {
    width: fit-content;
    margin-left: 0.3rem;
    padding: 0.3rem 0.1rem 0rem 0rem;
    font-size: 0.26rem;
    line-height: 0.26rem;
    border-bottom: 2px solid #3c37d2;
  }
  .fam-btns {
    padding: 0.3rem;
    margin-bottom: 0.3rem;
    border-bottom: 1px solid #eee;
    .btn {
      width: 1.5rem;
      margin-right: 0.15rem;
    }
  }
}
.tip {
  margin: 0.2rem;
}
</style>
